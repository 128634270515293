import { Component, model } from '@angular/core';

@Component({
  selector: 'husky-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  sidenavOpen = model<boolean>(false);
}
