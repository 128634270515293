import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';

import { AppSharedUiLetterAvatarComponent } from '@husky/app-shared-ui-avatar';
import { IconButtonDirective } from '@husky/app/shared/ui-button';

import { SidenavItemGroupComponent } from './components/sidenav/sidenav-item-group/sidenav-item-group.component';
import { SidenavItemComponent } from './components/sidenav/sidenav-item/sidenav-item.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { LayoutComponent } from './containers/layout/layout.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    SidenavItemGroupComponent,
    SidenavItemComponent,
    IconButtonDirective,
    MatIcon,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    AppSharedUiLetterAvatarComponent,
  ],
  declarations: [LayoutComponent, ToolbarComponent, SidenavComponent],
})
export class AppCoreLayoutModule {}
