import { CommonModule } from '@angular/common';
import { Component, ContentChildren, QueryList, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterLinkActive } from '@angular/router';

import { SidenavItemComponent } from '../sidenav-item/sidenav-item.component';

@Component({
  selector: 'husky-sidenav-item-group',
  standalone: true,
  imports: [CommonModule, RouterLinkActive, MatIcon],
  templateUrl: './sidenav-item-group.component.html',
  styleUrl: './sidenav-item-group.component.scss',
})
export class SidenavItemGroupComponent {
  label = input.required<string>();
  icon = input.required<string>();

  expanded = false;

  @ContentChildren(SidenavItemComponent, {
    descendants: true,
  })
  childItems?: QueryList<SidenavItemComponent>;

  get hasActiveChild() {
    return this.childItems?.some(
      (item) => item.routerLinkActive?.isActive ?? false,
    );
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
  }
}
