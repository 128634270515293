<div
  class="border-y border-transparent"
  [ngClass]="{ 'border-y-gray-200 bg-gray-100': expanded }">
  <div
    class="mt-0.5 flex cursor-pointer select-none items-center gap-3 border border-transparent py-2 font-medium transition ease-in-out"
    [ngClass]="{
      'mx-2 rounded-xl px-2 hover:border-gray-300 hover:bg-gray-600/10':
        !expanded,
      'px-4': expanded,
    }"
    (click)="toggleExpanded()">
    <mat-icon>{{ icon() }}</mat-icon>
    <span class="flex-1">{{ label() }}</span>
    <mat-icon class="flex-none" [class.rotate-90]="expanded"
      >chevron_right</mat-icon
    >
  </div>

  <div class="sidenav-item-group__children" *ngIf="expanded">
    <ng-content></ng-content>
  </div>
</div>
