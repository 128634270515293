export function getRandomBigInt(min: bigint, max: bigint) {
  if (min >= max) {
    throw new Error('Min value must be less than max value');
  }

  const range = max - min;
  const randomBytesLength = Math.ceil(range.toString(2).length / 8);

  let randomNumber;
  do {
    const randomBytes = new Uint8Array(randomBytesLength);
    crypto.getRandomValues(randomBytes);
    randomNumber = randomBytes.reduce(
      (acc, byte) => (acc << BigInt(8)) + BigInt(byte),
      BigInt(0),
    );
  } while (randomNumber >= range);

  return randomNumber + min;
}
