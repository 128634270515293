import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { HotToastService } from '@ngxpert/hot-toast';
import { filter, interval, startWith, tap } from 'rxjs';

import { AppSharedUiUpdateToastComponent } from '@husky/app-shared-ui-toast';

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  constructor(
    private readonly swUpdate: SwUpdate,
    private readonly toastService: HotToastService,
  ) {}

  listen(): void {
    if (this.swUpdate.isEnabled) {
      console.log('Service Worker Update enabled');
      this.swUpdate.unrecoverable.subscribe((event) => {
        console.error(`[${AppUpdateService.name}] Unrecoverable Error`, event);
        alert('Ein Fehler ist aufgetreten. Die App wird jetzt neu geladen.');
        window.location.reload();
      });

      this.swUpdate.versionUpdates
        .pipe(
          tap((event) =>
            console.log(
              `[${AppUpdateService.name}] Version Update Event`,
              event,
            ),
          ),
          filter(
            (event): event is VersionReadyEvent =>
              event.type === 'VERSION_READY',
          ),
        )
        .subscribe((event: VersionReadyEvent) => {
          console.log(
            `[${AppUpdateService.name}] Current Version`,
            event.currentVersion,
          );
          console.log(
            `[${AppUpdateService.name}] Available Version`,
            event.latestVersion,
          );

          this.showUpdateToast();
        });

      const everyMins$ = interval(60 * 1000).pipe(startWith(0));
      everyMins$.subscribe(() => this.swUpdate.checkForUpdate());
    }
  }

  showUpdateToast(): void {
    this.toastService.show(AppSharedUiUpdateToastComponent, {
      autoClose: false,
      dismissible: false,
    });
  }
}
