import { Component, computed } from '@angular/core';

import { AppSharedUtilAbilityService } from '@husky/app-shared-util-ability';
import {
  AppSharedUtilAuthService,
  AppSharedUtilAuthenticatedUserService,
} from '@husky/app-shared-util-auth';
import { AbilitySubject } from '@husky/shared-util-ability';

@Component({
  selector: 'husky-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  user = this.authenticatedUserService.user;

  canListInvoices = computed(() => {
    return this.ability.ability().can('list', AbilitySubject.Invoice);
  });

  canListProjects = computed(() => {
    return this.ability.ability().can('list', AbilitySubject.Project);
  });

  canListContacts = computed(() => {
    return this.ability.ability().can('list', AbilitySubject.Contact);
  });

  canListActivities = computed(() => {
    return this.ability.ability().can('list', AbilitySubject.Activity);
  });

  canListUsers = computed(() => {
    return this.ability.ability().can('list', AbilitySubject.User);
  });

  canListProducts = computed(() => {
    return this.ability.ability().can('list', AbilitySubject.Product);
  });

  canListSuppliers = computed(() => {
    return this.ability.ability().can('list', AbilitySubject.Supplier);
  });

  canListPurchaseOrder = computed(() => {
    return this.ability.ability().can('list', AbilitySubject.PurchaseOrder);
  });

  canListWarehouseReceipt = computed(() => {
    return this.ability.ability().can('list', AbilitySubject.WarehouseReceipt);
  });

  canListTimeEntry = computed(() => {
    return this.ability.ability().can('list', AbilitySubject.TimeEntry);
  });

  canListPurchaseInvoice = computed(() => {
    return this.ability.ability().can('list', AbilitySubject.PurchaseInvoice);
  });

  canCreateQuickEstimate = computed(() => {
    return this.ability.ability().can('create', AbilitySubject.QuickEstimate);
  });

  canListEmployee = computed(() => {
    return this.ability.ability().can('list', AbilitySubject.Employee);
  });

  constructor(
    private readonly authService: AppSharedUtilAuthService,
    private readonly authenticatedUserService: AppSharedUtilAuthenticatedUserService,
    private readonly ability: AppSharedUtilAbilityService,
  ) {}

  logout() {
    this.authService.logout();
  }

  reload(): void {
    window.location.reload();
  }
}
