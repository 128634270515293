import { isNil } from 'lodash';

/** The precision of the bigint number */
export const BIGINT_PRECISION = 8; // 8 decimal places
export const BIGINT_SCALE = BigInt(100000000); // 100000000n

export function scaleBigInt(value: bigint): bigint {
  return BigInt(value * BIGINT_SCALE);
}

export function unscaleBigInt(value: bigint): bigint {
  return value / BIGINT_SCALE;
}

function trimTrailingZeros(numberString: string) {
  return numberString.replace(/(\.0+|(?<=\..*?)0+)$/, '');
}

// TODO: Check this again and maybe remove the decimalPlaces param
export function bigIntToString(
  value: bigint,
  decimalPlaces: number = BIGINT_PRECISION,
): string {
  if (isNil(value)) {
    return '0.00';
  }

  const bigIntString = value.toString();

  if (bigIntString.length <= BIGINT_PRECISION) {
    const paddedDecimalPart = bigIntString.padStart(BIGINT_PRECISION, '0');
    const trimmedDecimalPart = paddedDecimalPart.slice(0, decimalPlaces); // Limiting decimal part to specified number of characters
    return trimTrailingZeros(
      `0.${trimmedDecimalPart.padEnd(decimalPlaces, '0')}`,
    );
  }

  const integerPart = bigIntString.slice(0, -BIGINT_PRECISION);
  const decimalPart = bigIntString.slice(-BIGINT_PRECISION);

  const nextDigit = Number(decimalPart[decimalPlaces + 1]);

  let trimmedDecimalPart = decimalPart;
  // If the next digit is >= 5, round up
  if (nextDigit >= 5) {
    trimmedDecimalPart = (
      Number(trimmedDecimalPart.slice(0, decimalPlaces)) + 1
    ).toString();
  } else if (nextDigit < 5) {
    trimmedDecimalPart = trimmedDecimalPart.slice(0, decimalPlaces);
  }

  return trimTrailingZeros(`${integerPart}.${trimmedDecimalPart}`);
}

export function stringToBigInt(value: string): bigint {
  const [integerPart, decimalPart] = value.split('.');
  const integer = BigInt(integerPart);

  if (!decimalPart) {
    return integer * BIGINT_SCALE;
  }

  const decimal = BigInt(
    decimalPart.slice(0, BIGINT_PRECISION).padEnd(BIGINT_PRECISION, '0'),
  );
  return integer * BIGINT_SCALE + decimal;
}
