// dialog.service.ts
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { HuskyDrawer } from '@husky/app-shared-ui-drawer';

interface DialogFragment {
  dialogId: string;
  id?: string;

  [key: string]: string | undefined; // Allow for additional parameters
}

@Injectable({
  providedIn: 'root',
})
export class DrawerUrlService {
  private currentFragment: string | null = null;
  private readonly DRAWER_PREFIX = 'drawer';

  constructor(
    private drawer: HuskyDrawer,
    private router: Router,
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const fragment = this.getFragment();

        if (fragment !== this.currentFragment) {
          this.currentFragment = fragment;
          this.handleDialogFromFragment(fragment);
        }
      });
  }

  private getFragment(): string | null {
    const tree = this.router.parseUrl(this.router.url);
    return tree.fragment;
  }

  private parseFragment(fragment: string | null): DialogFragment | null {
    if (!fragment) return null;

    try {
      // Check if this is a dialog fragment
      if (!fragment.startsWith(this.DRAWER_PREFIX + '?')) {
        return null;
      }

      // Remove the dialog prefix
      const paramsString = fragment.substring(this.DRAWER_PREFIX.length + 1);

      // Parse URL-style parameters
      const params = new URLSearchParams(paramsString);
      const dialogId = params.get('name');

      if (!dialogId) return null;

      // Convert URLSearchParams to plain object
      const result: DialogFragment = { dialogId };
      params.forEach((value, key) => {
        if (key !== 'name') {
          result[key] = value;
        }
      });

      return result;
    } catch (e) {
      console.warn('Failed to parse dialog fragment:', e);
      return null;
    }
  }

  private async handleDialogFromFragment(fragment: string | null) {
    if (!fragment) {
      this.drawer.closeAll();
      return;
    }

    console.log('fragment', fragment);

    const parsedFragment = this.parseFragment(fragment);
    console.log('parsedFragment', parsedFragment);
    if (!parsedFragment) return;

    const dialogConfig = await this.getDialogConfig(parsedFragment);
    console.log('dialogConfig', dialogConfig);
    if (!dialogConfig) return;

    const dialogRef = this.drawer.open(dialogConfig.component, {
      ...dialogConfig.config,
      data: {
        ...dialogConfig.config?.data,
        ...parsedFragment, // Pass all parameters to the dialog
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      if (this.getFragment() === fragment) {
        this.removeFragment();
      }
    });
  }

  private async getDialogConfig(params: DialogFragment) {
    switch (params.dialogId) {
      case 'activity':
        const { AppActivityFeatureActivityUpdateComponent } = await import(
          '@husky/app-activity-feature-activity-update'
        );
        return {
          component: AppActivityFeatureActivityUpdateComponent,
          config: {
            autoFocus: false,
            data: {
              defaultValues: {
                id: params.id,
              },
              redirectOnSuccess: false,
            },
          },
        };
      default:
        return null;
    }
  }

  private removeFragment() {
    this.currentFragment = null;
    this.router.navigate([], {
      fragment: undefined,
      replaceUrl: true,
    });
  }

  // Public method to open dialog via URL
  public openDialogViaUrl(
    dialogId: string,
    params: Record<string, string> = {},
  ) {
    const searchParams = new URLSearchParams({
      name: dialogId,
      ...params,
    });

    const fragment = `${this.DRAWER_PREFIX}?${searchParams.toString()}`;

    this.router.navigate([], {
      fragment,
      replaceUrl: true,
    });
  }
}
