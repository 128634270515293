import { Route } from '@angular/router';

import { AppSharedUtilAuthGuard } from '@husky/app-shared-util-auth';
import { LayoutComponent } from '@husky/app/core/layout';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    loadChildren: () =>
      import('@husky/app-auth-shell').then((m) => m.AppAuthShellModule),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AppSharedUtilAuthGuard],
    canActivateChild: [AppSharedUtilAuthGuard],
    children: [
      {
        path: 'sales',
        loadChildren: () =>
          import('@husky/app-sales-shell').then((m) => m.AppSalesShellModule),
      },
      {
        path: 'inventory',
        loadChildren: () =>
          import('@husky/app-inventory-shell').then(
            (m) => m.AppInventoryShellModule,
          ),
      },
      {
        path: 'activity',
        loadComponent: () =>
          import('@husky/app-activity-feature-activity-grid').then(
            (m) => m.AppActivityFeatureActivityGridPageComponent,
          ),
      },
      {
        path: 'activity/gantt',
        loadComponent: () =>
          import('@husky/app-activity-feature-activity-gantt').then(
            (m) => m.AppActivityFeatureActivityGanttComponent,
          ),
      },
      {
        path: 'setting',
        loadChildren: () =>
          import('@husky/app-setting-shell').then(
            (m) => m.AppSettingShellModule,
          ),
      },
      {
        path: 'hr',
        loadChildren: () =>
          import('@husky/app-hr-shell').then((m) => m.AppHrShellModule),
      },
      {
        path: 'accounting',
        loadChildren: () =>
          import('@husky/app-accounting-shell').then(
            (m) => m.AppAccountingShellModule,
          ),
      },
      // {
      //   path: 'accounting/customer',
      //   loadChildren: () =>
      //     import('@husky/app/customer/shell').then(
      //       (m) => m.AppCustomerShellModule,
      //     ),
      // },
      // {
      //   path: 'accounting/invoice',
      //   loadChildren: () =>
      //     import('@husky/app/invoice/shell').then(
      //       (m) => m.AppAccountingShellModule,
      //     ),
      // },
      // {
      //   path: 'accounting/banking',
      //   loadChildren: () =>
      //     import('@husky/app/banking/shell').then(
      //       (m) => m.AppBankingShellModule,
      //     ),
      // },
      // {
      //   path: 'project',
      //   loadChildren: () =>
      //     import('@husky/app/project/shell').then(
      //       (m) => m.AppProjectShellModule,
      //     ),
      // },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'activity',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/',
  },
];
