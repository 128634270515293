<mat-sidenav-container class="h-full">
  <mat-sidenav
    #sidenav
    [fixedInViewport]="true"
    [mode]="sidenavMode()"
    [opened]="sidenavOpen()"
    (openedChange)="sidenavOpen.set($event)">
    <husky-sidenav class="flex h-full w-64 flex-col"></husky-sidenav>
  </mat-sidenav>

  <mat-sidenav-content>
    <husky-toolbar
      class="husky-layout__toolbar"
      [(sidenavOpen)]="sidenavOpen"></husky-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
