import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

import {
  ButtonDirective,
  IconButtonDirective,
} from '@husky/app/shared/ui-button';

@Component({
  selector: 'husky-app-shared-ui-update-toast',
  standalone: true,
  imports: [CommonModule, ButtonDirective, MatIcon, IconButtonDirective],
  templateUrl: './app-shared-ui-update-toast.component.html',
  styleUrl: './app-shared-ui-update-toast.component.css',
})
export class AppSharedUiUpdateToastComponent {
  reload(): void {
    window.location.reload();
  }
}
